import './NotFound.less'

import { useLocation } from '@reach/router'
import { Button } from 'antd'
import { navigate, useIntl } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import DatacIcon from '../../assets/images/datac-icon.svg'
import NotFoundImage from '../../assets/images/not-found.svg'
import { routes } from '../../routes'
import { DatacSubtitle, DatacTitle } from '../common'

export const NotFound: React.FC<{ forceUserHome?: boolean }> = ({ forceUserHome }) => {
  const intl = useIntl()
  const intlNotFound = (scopedId: string) => intl.formatMessage({ id: `not_found.${scopedId}` })
  const location = useLocation()
  const [homeRoute, setHomeRoute] = useState(forceUserHome ? routes.home : routes.subjectDashboard)

  useEffect(() => {
    const firstStringAfterLanguage = location.pathname.split('/')[2]
    // only for this beginnings of route we use user home, otherwise it's subject
    if (['auth', 'settings', 'studies'].includes(firstStringAfterLanguage)) setHomeRoute(routes.home)
  }, [location])

  return (
    <div className="not-found">
      <NotFoundImage className="not-found__image" />
      <DatacIcon className="not-found__datac-icon" />
      <DatacTitle type="h1" className="not-found__title">
        {intlNotFound('title')}
      </DatacTitle>
      <DatacSubtitle type="h2" className="not-found__subtitle">
        {intlNotFound('subtitle')}
      </DatacSubtitle>
      <Button className="not-found__button" type="primary" onClick={() => navigate(homeRoute)}>
        <span>{intlNotFound('go_home')}</span>
      </Button>
    </div>
  )
}
